<template>
  <!-- 发布信息 -->
  <div class="post-message">
      <PipaInput @publish="$emit('publish')"/>
      <!-- 添加图片 -->
<!--      <div class="add-images">-->
<!--        <ul>-->
<!--          <li v-for="i in 9" :key="i"></li>-->
<!--        </ul>-->
<!--      </div>-->
  </div>
</template>

<script>
import PipaInput from '@/components/common/pipa/PipaInput'
export default {
  name: 'PipaDefaultModePublish',
  components: { PipaInput }
}
</script>

<style scoped lang="scss">
  @import "../../../../assets/style/variables";
  // 发布消息
  .post-message {
    background-color: $background-color;
    padding: 20px 25px;
    margin-bottom: 10px;
    // 输入
    textarea {
      width: 100%;
      height: 100px;
      padding: 10px 15px;
      outline: none;
      resize: none;
      border: 0;
      box-sizing: border-box;
      background-color: $background-color-second;
    }
    // 添加图片
    .add-images {
      ul {
        padding: 0;
        margin: 0;
        width: 300px;
        flex-wrap: wrap;
        li {
          display: inline-block;
          width: 66px;
          height: 57px;
          margin: 10px 10px 0 0;
          background-color: $font-color-forth;
        }
      }
    }
    // 多功能
    .multifunction {
      ul {
        padding: 0;
        margin: 10px 0 0 0;
        display: flex;
        li {
          margin-left: 10px;
          button {
            line-height: 12px;
            font-size: 13px;
          }
          &:first-of-type {
            margin: 0;
          }
          &:last-of-type {
            width: 100%;
            text-align: right;
          }
          span {
            margin-left: 5px;
          }
        }
      }
    }
  }
</style>
