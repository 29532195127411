<template>
  <div class="uploading-tip" :style="{ width, height }">
    <Icon type="el-icon-loading"/><span>{{tip}}</span>
  </div>
</template>

<script>
export default {
  name: 'UploadingTip',
  props: {
    // 提示文字
    tip: {
      type: String,
      default: '上传中，请稍后...'
    },
    // 提示文字颜色
    color: {
      type: String,
      default: '#999'
    },
    // 宽度
    width: {
      type: String,
      default: '200px'
    },
    // 高度
    height: {
      type: String,
      default: '150px'
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
.uploading-tip {
  background-color: $background-color-second;
  color: $font-color-third;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid $background-color-second - 5;
  // animation: shine 2s infinite;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 13px;
  i {
    font-size: 25px !important;
    margin-bottom: 10px;
  }
}
@keyframes shine {
  0% {
    background-color: #fff;
    font-size: 15px;
    border-width: 1px;
  }
  50% {
    background-color: $background-color-second;
    font-size: 14px;
    border-width: 5px;
  }
  100% {
    background-color: #fff;
    font-size: 15px;
    border-width: 1px;
  }
}
</style>
