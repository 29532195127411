<template>
  <div class="user-data">
    <!-- 用户资料 -->
    <div>
      <img src="http://img2.woyaogexing.com/2022/06/24/e111e11a15fa086f!400x400.jpg">
      <div>
        <span>梦回上瘾</span>
        <UserMedals/>
        <span>腾讯科技/WEB前端工程师</span>
      </div>
    </div>
    <ul class="data">
      <li>
        <span>50</span>
        <span>pipa圈</span>
      </li>
      <li>
        <span>350</span>
        <span>关注</span>
      </li>
      <li>
        <span>999</span>
        <span>粉丝</span>
      </li>
    </ul>
  </div>
</template>

<script>
import UserMedals from './UserMedals'
export default {
  name: 'UserCard',
  components: { UserMedals }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/style/variables";
  // 用户
  .user-data {
    background-color: $background-color;
    padding: 0 10px 5px 10px;
    // 用户资料
    & > div {
      padding: 15px 0;
      box-sizing: border-box;
      border-bottom: 1px solid $background-color-second;
      display: flex;
      img {
        width: 58px;
        height: 58px;
        border-radius: 50%;
      }
      div {
        margin-left: 10px;
        span {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 170px;
          font-size: 14px;
          color: $font-color-third;
          &:first-of-type {
            font-weight: 600;
            color: #1D1D1D;
            font-size: 17px;
            position: relative;
            top: -3px;
          }
        }
      }
    }
    // 用户数据
    .data {
      margin: 15px 0 5px 0;
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      padding: 0;
      li {
        display: flex;
        flex-direction: column;
        width: 30%;
        span {
          color: $font-color-third;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          font-size: 13px;
          &:first-of-type{
            font-size: 17px;
            color: #1D1D1D;
            text-align: center;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
</style>
