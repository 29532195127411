<template>
  <el-popover placement="bottom" trigger="hover" popper-class="circle-popover" v-model="visible">
    <div class="circle-popover">
      <el-input
        class="filter-input"
        placeholder="输入关键字进行过滤"
        prefix-icon="el-icon-search"
        v-model="filterText">
      </el-input>
      <div class="circle-wrap">
        <div class="circle-blk" v-for="item in groupTypes" :key="item.id" @click="checkedGroup(item)">
          <img :src="item.iconUri" alt="">
          <div class="blk-right">
            <h4>{{item.name}}</h4>
            <!-- <p>12334圈友</p> -->
          </div>
        </div>
      </div>
      <el-button plain class="no-check" @click="checkedGroup('')">不选择圈子</el-button>
    </div>
    <el-button slot="reference" type="primary" plain size="mini" round class="check-btn">
      <template v-if="checkedItem">
        <img :src="checkedItem.iconUri" alt="" class="checked-icon"> {{checkedItem.name}}
      </template>
      <template v-else>请选择圈子 &gt;</template>
    </el-button>
  </el-popover>
</template>

<script>
import { fetchGroupListList } from '@/api/pipa'
import { debounce } from '@/utils/utils'
export default {
  name: 'GroupList',
  props: {
    value: [String, Number]
  },
  data () {
    return {
      visible: false,
      filterText: '', // 搜索文字
      checkedItem: null, // 当前选中圈子
      allGroupList: [], // 所有圈子列表
      groupTypes: [] // 过滤后的圈子列表
    }
  },
  methods: {
    filterNode (value) {
      if (!value) return (this.groupTypes = [].concat(this.allGroupList))
      this.groupTypes = this.allGroupList.filter(item => item.name.indexOf(value) !== -1)
    },
    checkedGroup (item) {
      this.checkedItem = item || null
      this.$emit('input', item ? item.id : '')
      this.visible = false
    },
    async getGroupList () {
      const resData = await fetchGroupListList()
      this.allGroupList = [].concat(resData)
      this.groupTypes = [].concat(resData)
    }
  },
  mounted () {
    this.getGroupList()
    document.getElementsByClassName('filter-input')[0].addEventListener('input', debounce((e) => {
      this.filterNode(e.target.value)
    }, 600))
  }
}
</script>

<style scoped lang="scss">
.circle-popover {
  position: relative !important;
  padding-bottom: 40px;
 /deep/ .filter-input {
    input {
      border-radius: 15px;
    }
  }
  .circle-wrap {
    margin: 10px 0;
    .circle-blk {
      display: flex;
      align-items: center;
      padding: 8px 0;
      & > img {
        height: 35px;
        margin-right: 10px;
      }
      .blk-right {
        & > h4 {
          margin: 0;
        }
        & > p {
          margin: 10px 0 0;
        }
      }
    }
  }
  .no-check {
    position: absolute;
    right: 0px;
    bottom: 0px;
    background: #eee;
    border: none;
    color: #9f9c9c;
  }
}
/deep/ .el-button span {
  display: flex;
  align-items: center;
}
/deep/ .check-btn {
  margin-bottom: 8px;
  height: 28px;
}
.checked-icon {
  height: 15px;
  width: 15px;
  margin-right: 6px;
}
</style>
