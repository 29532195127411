<template>
  <div class="pipa-share">
    <!-- 左侧部 -->
    <div class="left" v-loading="status.loadingLeft">
      <div class="ceiling">
        <!-- 用户 -->
        <UserCard/>
        <!-- 挑选 -->
        <PipaFilter @change="changeFilter"/>
      </div>
    </div>
    <!-- 右侧部   -->
    <div class="right" v-loading="status.loadingRight">
      <!-- 一般模式 -->
      <PipaDefaultModePublish @publish="$refs.pipaList.fetchList()"/>
      <!-- 许愿模式 -->
<!--      <PipaDesireModePublish/>-->
      <!-- 浏览动态 -->
      <PipaList ref="pipaList"/>
    </div>
  </div>
</template>

<script>
import PipaList from '@/components/common/pipa/PipaList'
import UserCard from '@/components/common/usr/UserCard'
import PipaFilter from '@/components/common/pipa/Filter'
import PipaDefaultModePublish from '@/components/common/pipa/publish/DefaultMode'
// import PipaDesireModePublish from '@/components/common/pipa/publish/DesireMode'

export default {
  components: { PipaDefaultModePublish, PipaFilter, UserCard, PipaList },
  data () {
    return {
      status: {
        loadingLeft: false,
        loadingRight: false
      }
    }
  },
  methods: {
    changeFilter (type, value) {
      this.$refs.pipaList.pipaData.pagination.pageIndex = 1
      if (type === 'sort') {
        this.$refs.pipaList.pipaData.params.sorts = value
      } else {
        this.$refs.pipaList.pipaData.params.model[type] = value
        this.$refs.pipaList.pipaData.params.sorts = [{
          direction: 'DESC',
          property: 'CREATE_TIME'
        }]
      }
      this.$refs.pipaList.fetchList()
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/style/variables";
  .pipa-share {
    margin-top: 20px;
    width: 1080px;
    display: flex;
    padding-bottom: 60px;
    // 左侧
    .left {
      flex-shrink: 0;
      margin-right: 20px;
      .ceiling {
        position: sticky;
        top: 20px;
      }
    }
    // 右侧
    .right {
      flex-grow: 1;
      background-color: $background-color-second;
      overflow: hidden;
    }
  }
  .pipa-list {
    margin-top: 20px;
  }
</style>
