<template>
  <div class="pipa-input">
    <!-- 输入 -->
    <Editor
      style="height: 100px;overflow-y: hidden;"
      :defaultConfig="editorConfig"
      mode="default"
      @onCreated="handleCreated"
      @keydown.native="handleKeydown"
    />
    <!-- 图片 -->
    <ul class="images">
      <li v-for="(image,index) in pipaData.images" :key="index">
        <el-image fit="cover" :src="image.uri" :preview-src-list="pipaData.images.map(img => img.uri)"/>
        <span class="btn-close" @click="deleteImage(index)">
          <Icon type="el-icon-close"/>
        </span>
      </li>
      <li class="btn-add-image" v-if="pipaData.images.length !== 0 && pipaData.images.length < 9">
        <Uploader action="/api/upload/pipa/image" @success="handleUploadImageSuccess">
          <section>
            <Icon type="el-icon-plus"/>
          </section>
        </Uploader>
      </li>
    </ul>
    <!-- 视频 -->
    <div class="video-wrap" v-if="pipaData.video != null">
      <MiniVideoPlayer :video="pipaData.video"/>
    </div>
    <div class="video-uploading" v-if="pipaData.videoUploading">
      <UploadingTip tip="视频上传中，请稍后..." width="400px" height="225px"/>
    </div>
    <!-- 底部操作 -->
    <div class="footer">
      <div class="footer-left">
        <GroupList v-model="pipaData.type"></GroupList>
        <ul class="toolbar">
          <li><Emoji @select="sendEmoji"/></li>
          <li>
            <Uploader action="/api/upload/pipa/image" @success="handleUploadImageSuccess">
              <Button type="text" theme="info" size="mini" ><Icon type="icon-charutupian" size="small"/></Button>
            </Uploader>
          </li>
          <li>
            <Uploader
              action="/api/upload/pipa/video"
              type="video"
              :limit-size="100"
              @before-upload="pipaData.videoUploading = true"
              @success="handleUploadVideoSuccess"
              @error="pipaData.videoUploading = false"
            >
              <Button type="text" theme="info" size="mini"><Icon type="icon-shipin" size="small"/></Button>
            </Uploader>
          </li>
          <li><Button type="text" theme="info" size="mini"><Icon type="icon-aixin" size="small"/></Button></li>
        </ul>
      </div>
      <section>
        <Button type="solid" theme="function" size="big" :disabled="pipaData.isWorking" @click="publish">
          <Icon type="icon-wangluo"/>&nbsp;发布
        </Button>
      </section>
    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import { mapMutations } from 'vuex'
import { Editor } from '@wangeditor/editor-for-vue'
import { useEmoji, publishPipa } from '@/api/user'
import Emoji from '@/components/common/Emoji'
import Uploader from '@/components/common/Uploader'
import MiniVideoPlayer from '@/components/common/MiniVideoPlayer'
import UploadingTip from '@/components/common/UploadingTip'
import GroupList from './module/GroupList'
import BaseComponent from '@/components/base/BaseComponent'

export default {
  name: 'PipaInput',
  extends: BaseComponent,
  components: { UploadingTip, MiniVideoPlayer, Uploader, Emoji, Editor, GroupList },
  props: {
    value: {
      type: String
    }
  },
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    }
  },
  data () {
    const vm = this
    return {
      editor: null,
      filterText: '',
      editorConfig: {
        placeholder: '学归学，玩归玩！发个pipa圈唠唠嗑公司不会倒～',
        // 内容悬浮内容处理
        hoverbarKeys: {
          text: {
            menuKeys: []
          },
          // 去掉图片处理
          image: {
            menuKeys: []
          }
        },
        MENU_CONF: {
          // 图片上传配置
          uploadImage: {
            // 文件参数名称
            fieldName: 'file',
            // 文件大小限制（5M）
            maxFileSize: 5 * 1024 * 1024,
            // 上传地址
            server: '/api/upload/pipa/image',
            // 自定义插入图片
            customInsert (res, insertFn) {
              if (!res.success) {
                // TODO 提示上传失败
                return
              }
              // 插入图片
              vm.pipaData.images.push({
                id: res.data.id,
                uri: vm.imagePrefix + res.data.fileKey
              })
              // 聚焦输入
              setTimeout(() => {
                vm.focus()
              }, 300)
            }
          }
        }
      },
      // pipa数据
      pipaData: {
        isWorking: false,
        // 正在上传视频
        videoUploading: false,
        type: '',
        // 视频
        video: null,
        // 图片
        images: []
      }
    }
  },
  methods: {
    ...mapMutations(['topFavoriteEmoji']),
    // 处理编辑器创建
    handleCreated (editor) {
      // 一定要用 Object.seal() ，否则会报错
      this.editor = Object.seal(editor)
    },
    // 按键处理
    handleKeydown (event) {
      // 回车
      if (event.keyCode === 13) {
        event.stopPropagation()
        event.preventDefault()
        // 按住shift键时执行换行
        if (event.shiftKey) {
          this.editor.insertBreak()
          return
        }
        this.$emit('enter')
      }
    },
    // 发送表情
    sendEmoji ({ uri, alt, id }) {
      // 更新使用时间
      useEmoji(id).then().catch()
      // 添加图片至输入面板中（根据wangeditor的要求，无法添加class，但可以添加alt，通过alt来代替class以达到控制表情图片的目的）
      this.editor.setHtml(`${this.editor.getHtml()}<img src='${uri}' alt="${alt}"/>`)
      setTimeout(() => {
        // 自动聚焦，此处需要延时才会生效
        this.focus()
      }, 300)
    },
    // 图片上传成功
    handleUploadImageSuccess (data) {
      this.pipaData.images.push({
        id: data.id,
        uri: this.imagePrefix + data.fileKey
      })
    },
    // 视频上传成功
    handleUploadVideoSuccess (data) {
      this.pipaData.videoUploading = false
      this.pipaData.video = data
    },
    // 发布
    publish () {
      if (this.pipaData.isWorking) {
        return
      }
      this.pipaData.isWorking = true
      publishPipa({
        content: this.editor.getHtml(),
        images: this.pipaData.images.map(item => item.id),
        videoId: this.pipaData.video == null ? null : this.pipaData.video.id,
        type: this.pipaData.type,
        // type: 'COMMON',
        groupId: 1
      })
        .then(() => {
          this.pipaData.images = []
          this.pipaData.video = null
          this.editor.clear()
          this.$message.success('发布成功')
          this.$emit('publish')
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.pipaData.isWorking = false
        })
    },
    // 聚焦
    focus () {
      this.editor.focus(true)
    },
    // 删除图片
    deleteImage (index) {
      this.pipaData.images.splice(index, 1)
    }
  },
  // 组件销毁时，及时销毁编辑器
  beforeDestroy () {
    const editor = this.editor
    if (editor == null) return
    editor.destroy()
  },
  mounted () {
    Sortable.create(document.querySelector('.images'), {
      animation: 300
    })
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/variables";
.pipa-input {
  // 底部
  .footer {
    display: flex;
    align-items: flex-end;
    .footer-left {
      flex-grow: 1;
      // 工具栏
      .toolbar {
        padding: 0;
        margin: 0;
        display: flex;
        color: $font-color-third;
        li {
          margin-right: 10px;
        }
      }
    }
    section {
      width: 150px;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      i {
        position: relative;
        top: -2px;
      }
      button {
        font-size: 14px;
        margin-bottom: 6px;
      }
    }
  }
  // 图片
  .images {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li {
      width: 125px;
      height: 125px;
      padding: 5px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: contain;
      position: relative;
      // 图片
      .el-image {
        width: 100%;
        height: 100%;
        /deep/ img {
          transition: all ease .5s;
        }
        &:hover {
          /deep/ img {
            transform: scale(1.1);
          }
        }
      }
      // 关闭按钮
      .btn-close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, .5);
        transition: all ease .15s;
        border-radius: 50%;
        i {
          color: #fff;
        }
        &:hover {
          background-color: rgba(0, 0, 0, .7);
        }
      }
      // 添加图片
      &.btn-add-image {
        & > div {
          width: 100%;
          height: 100%;
        }
        /deep/ .el-upload {
          display: block;
          width: 100%;
          height: 100%;
        }
        section {
          width: 100%;
          height: 100%;
          background-color: $font-color-forth;
          transition: all ease .15s;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            font-size: 30px;
            color: $font-color-third;
          }
          &:hover {
            transition: all ease .15s;
            background-color: $font-color-forth - 5;
            i {
              color: $font-color-second;
            }
          }
        }
      }
    }
  }
  // 文本编辑
  /deep/ .w-e-text-container {
    p {
      margin: 0;
      font-size: 15px;
      line-height: 25px;
    }
    // 图片外壳
    .w-e-image-container {
      margin: 0;
      // 去掉图片拖拽改变大小的处理
      .w-e-image-dragger {
        display: none;
      }
      &:hover {
        box-shadow: none;
      }
      img {
        max-width: 200px;
      }
    }
    // 已选图片外壳
    .w-e-selected-image-container {
      overflow: initial;
    }
  }
  // placeholder
  /deep/ .w-e-text-placeholder {
    font-style: normal;
    top: 3px;
  }

}
</style>
<style lang="scss">

</style>
