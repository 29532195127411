<template>
  <div class="filtrate">
    <!-- 挑选 -->
    <ul>
      <li v-for="item in sortOptions" :key="item.value" :class="{checked: currentChecked == item.value}" @click="changeType('sort', item.value)">
        <Button :round="false">
          <Icon :type="item.icon"/>
          <span>{{item.name}}</span>
        </Button>
      </li>
      <li>
        <Button :round="false">
          <Icon type="icon-shipin"/>
          <span>关注</span>
        </Button>
      </li>
    </ul>
    <!-- 选择栏 -->
    <ul>
      <li v-for="item in groupTypes" :key="item.id"
        @click="changeType('groupId', item.id)"
        :class="{checked: currentChecked == item.id}">
        <img :src="item.iconUri" alt="">
        <span>{{item.name}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { fetchGroupListList } from '@/api/pipa'
export default {
  name: 'PipaFilter',
  data () {
    return {
      currentChecked: 'CREATE_TIME', // 当前选中
      sortOptions: [
        { icon: 'icon-shipin', name: '最新', value: 'CREATE_TIME' },
        { icon: 'icon-shipin', name: '热门', value: 'MESSAGE_COUNT' }
      ],
      groupTypes: []
    }
  },
  methods: {
    async getGroupList () {
      this.groupTypes = await fetchGroupListList()
    },
    changeType (type, value) {
      this.currentChecked = value
      if (type === 'sort') {
        this.$emit('change', type, [{ direction: 'DESC', property: value }])
      } else {
        this.$emit('change', type, value)
      }
    }
  },
  created () {
    this.getGroupList()
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/style/variables";
  .filtrate {
    // 筛选
    ul {
      background-color: $background-color;
      padding: 20px 20px;
      box-sizing: border-box;
      margin: 20px 0 0 0;
      li {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        border-radius: 6px;
        line-height: 40px;
        border: 1px solid transparent;
        cursor: pointer;
        background: #fff;
        transition: all ease .5s;
        &:hover {
          background: $primary-color-hover;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
        &.checked {
          background: $primary-color-hover;
          color: $primary-color;
        }
        img {
          margin-right: 10px;
          height: 30px;
        }
        button {
          font-size: 17px;
          width: 215px;
          height: 40px;
          line-height: 10px;
          text-align: left;
          // background-color: $background-color;
          background: inherit;
          color: inherit !important;
          border: 0;
          padding: 0;
          &:hover {
            background-color: $primary-color-hover;
          }
          span {
            margin-left: 5px;
          }
        }
      }
    }
  }
</style>
