<template>
  <el-upload
    :action="action"
    :accept="accept"
    :show-file-list="false"
    :before-upload="handleBeforeUpload"
    :on-error="handleUploadError"
    :on-success="handleUploadSuccess"
  >
    <slot></slot>
  </el-upload>
</template>

<script>
export default {
  name: 'Uploader',
  props: {
    // 上传接口
    action: {
      type: String,
      required: true
    },
    // 上传类型，image图片，video视频
    type: {
      type: String,
      default: 'image'
    },
    // 限制大小（单位兆）
    limitSize: {
      type: Number,
      default: 5
    }
  },
  computed: {
    // 上传格式
    accept () {
      // 图片
      if (this.type === 'image') {
        return '.png,.jpg,jpeg,.gif'
      }
      // 视频
      if (this.type === 'video') {
        return '.mp4'
      }
      return ''
    }
  },
  methods: {
    // 文件上传前
    handleBeforeUpload (file) {
      const size = file.size / 1024 / 1024
      if (size > this.limitSize) {
        this.$message.warning('文件大小不能超过' + this.limitSize + 'M')
        return false
      }
      this.$emit('before-upload')
      return true
    },
    // 文件上传失败
    handleUploadError (err) {
      this.$message.warning(err)
      this.$emit('error', err)
    },
    // 文件上传成功
    handleUploadSuccess (response) {
      if (!response.success) {
        this.$message.warning(response.message)
        this.$emit('error', response.message)
        return
      }
      this.$emit('success', response.data)
    }
  }
}
</script>

<style scoped>

</style>
